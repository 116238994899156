import { Box, Paper, TextField } from "@material-ui/core";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";

const AutoCompleteInput = forwardRef(
  ({ value, options, onChange, onSelection, onKeyDown }, ref) => {
    const textFieldRef = useRef();
    const searchBoxRef = useRef();
    const itemRefs = useRef([]); // Array to hold refs for each dropdown item
    const [selectedIndex, setSelectedIndex] = useState(-1); // Index of the currently highlighted item
    const [inputFocused, setInputFocused] = useState(true); // Track input focus

    const handleChange = (e) => {
      onChange(e);
      setSelectedIndex(-1); // Reset index when typing
    };

    // Relevance sorting helper function
    const getRelevanceScore = (item, searchTerm) => {
      const name = item?.name?.toLowerCase();
      const secondaryName = item?.secondary_name?.toLowerCase();
      const search = searchTerm.toLowerCase();

      // Exact matches at the beginning of the name get the highest score
      if (name?.startsWith(search)) return 3;
      if (secondaryName?.startsWith(search)) return 2;

      // Partial matches get a lower score
      if (name?.includes(search)) return 1;
      if (secondaryName?.includes(search)) return 0;

      // No match
      return -1;
    };

    const dropdownOptions = useMemo(() => {
      const filteredItems = options
        ?.map((item) => ({
          ...item,
          relevance: getRelevanceScore(item, value),
        }))
        ?.filter((item) => item.relevance >= 0) // Only include relevant items
        ?.sort((a, b) => b.relevance - a.relevance); // Sort by relevance score

      console.log("filteredItems:", filteredItems);
      return filteredItems;
    }, [value, options]);

    useEffect(() => {
      if (selectedIndex >= 0 && itemRefs.current[selectedIndex]) {
        // Scroll the parent container to make the selected item visible
        itemRefs.current[selectedIndex].focus();
        itemRefs.current[selectedIndex].scrollIntoView({
          behavior: "smooth",
          block: "nearest",
        });
      }
    }, [selectedIndex]);

    useImperativeHandle(
      ref,
      () => {
        return {
          textFieldRef: textFieldRef.current,
          searchBoxRef: searchBoxRef.current,
        };
      },
      []
    );

    const selectProduct = (index) => {
      onSelection(dropdownOptions[index]);
      setSelectedIndex(-1); // Reset after selection
      setInputFocused(true); // Return focus to input
      textFieldRef.current.focus();
    };

    const highlightText = (text, highlight) => {
      const parts = text?.split(new RegExp(`(${highlight})`, "gi"));
      return (
        <>
          {parts?.map((part, index) =>
            part.toLowerCase() === highlight.toLowerCase() ? (
              <span key={index} style={{ backgroundColor: "yellow" }}>
                {part}
              </span>
            ) : (
              part
            )
          )}
        </>
      );
    };

    const handleKeyDown = (e) => {
      console.log("KEY PRESSED: ", e.key, e.code);
      if (onKeyDown) onKeyDown(e); // Call parent onKeyDown if provided

      if (e.code === "ArrowDown" && dropdownOptions.length > 0) {
        // Move focus to the first dropdown item
        setSelectedIndex(0);
        setInputFocused(false);
      } else if (e.code === "ArrowUp") {
        setSelectedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : dropdownOptions.length - 1
        );
      } else if (e.code === "Enter") {
        if (dropdownOptions[selectedIndex]) {
          onSelection(dropdownOptions[selectedIndex]);
          setSelectedIndex(-1); // Reset after selection
          setInputFocused(true); // Return focus to input
          textFieldRef.current.focus(); // Focus on text field
        }
      } else {
        setInputFocused(true); // Return focus to input on any other key press
      }
    };

    const handleItemKeyDown = (e, index) => {
      if (e.code === "ArrowDown") {
        // Move to next item
        setSelectedIndex((prevIndex) =>
          prevIndex < dropdownOptions.length - 1 ? prevIndex + 1 : 0
        );
      } else if (e.code === "ArrowUp") {
        // Move to previous item
        setSelectedIndex((prevIndex) =>
          prevIndex > 0 ? prevIndex - 1 : dropdownOptions.length - 1
        );
      } else if (e.code === "Enter") {
        selectProduct(index);
      } else {
        // Return focus to input for any other key press
        setInputFocused(true);
        textFieldRef.current.focus();
      }
    };

    return (
      <Box display={"flex"}>
        <Box flex={1}>
          <TextField
            onKeyDown={handleKeyDown}
            inputRef={textFieldRef}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            placeholder="Select Product"
            value={value}
            onFocus={() => setInputFocused(true)}
          />
        </Box>
        <Box
          display={
            value.length && dropdownOptions.length > 0 ? "block" : "none"
          }
          style={{ padding: "0px 20px" }}
          width={"35%"}
          maxHeight={"200px"}
          overflow={"auto"}
        >
          <Paper ref={searchBoxRef}>
            {dropdownOptions.map((item, index) => {
              const isSelected = index === selectedIndex;
              return (
                <Box
                  key={item.id}
                  ref={(el) => (itemRefs.current[index] = el)} // Assign each item a ref
                  padding={"10px"}
                  style={{
                    backgroundColor: isSelected ? "#b3d4fc" : "transparent",
                    cursor: "pointer",
                  }}
                  tabIndex={-1} // Make each item focusable
                  onClick={()=>selectProduct(index)}
                  onKeyDown={(e) => handleItemKeyDown(e, index)}
                >
                  {highlightText(item.name, value)} (
                  {highlightText(item.secondary_name, value)})
                </Box>
              );
            })}
          </Paper>
        </Box>
      </Box>
    );
  }
);

export default AutoCompleteInput;
