import {
  Box,
  Button,
  DialogContentText,
  Grid,
  IconButton,
  useTheme,
} from "@material-ui/core";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import CloseIcon from "@material-ui/icons/Close";
import _ from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Modal from "../../MuiComponent/Modal";
import AntSwitch from "../../components/AntSwitch";
import { useAuth } from "../../providers/AuthProvider";
import { currency } from "../../utils/utility";
import { useFormik } from "formik";
import { updateConfigurations } from "../../store/actions";
var converter = require("number-to-words");

{
  /* <style>
.page-break {
    page-break-after: always;
}
table{
    border-collapse: collapse;
    width: 100%;
}
</style> */
}

function Invoice({ data, onBack, updateStatus }) {
  const theme = useTheme();
  const { userData } = useAuth();
  const dispatch = useDispatch();
  const [modalStatus, setModalStatus] = useState(false);
  const [isDiscountProvided, setIsDiscountProvider] = useState(false);

  const {
    userData: { businessDetails },
  } = useSelector((state) => state.general);

  const { values, setFieldValue, submitForm } = useFormik({
    initialValues: {
      ...businessDetails?.business_configurations?.bill_configurations,
    },
    onSubmit: () => {
      dispatch(
        updateConfigurations({
          key: "bill_configurations",
          value: values,
        })
      );
    },
  });

  useEffect(() => {
    if (values.customer_copy) {
      const element = document.querySelector("#copy_table");
      const elementToCopy = document.querySelector("#customer_copy");

      elementToCopy.innerHTML = element.innerHTML;
    }

    data.items.map((item) => {
      if (item.discount > 0) {
        setIsDiscountProvider(true);
      }
    });
  }, [data, values, isDiscountProvided]);

  const ModalHeader = () => {
    return (
      <>
        <Box
          display="flex"
          justifyContent="space-between"
          style={{ fontSize: "14px" }}
        >
          <Box textAlign="left" style={{ fontWeight: 600 }} padding={1}>
            Bill Settings
          </Box>
          <IconButton
            style={{ position: "absolute", right: "20px" }}
            onClick={() => {
              setModalStatus(!modalStatus);
            }}
          >
            <CloseIcon size="small" />
          </IconButton>
        </Box>
      </>
    );
  };

  return (
    <>
      <Modal
        role="add-sales-dialog"
        headerComponent={ModalHeader}
        height="320px"
        // buttonAlign="center"
        width="100%"
        closeBtnText="Cancel"
        saveBtnText="Proceed"
        // loading={actionApiStatus.isPending}
        saveHandler={() => {
          setModalStatus(!modalStatus);
          submitForm();
        }}
        closeHandler={() => {
          setModalStatus(!modalStatus);
        }}
        status={modalStatus}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            {/* <Grid item xs={6}>
              <Box alignItems={"center"} display={"flex"}>
                <Box flex={1} component={"span"}>
                  Footer
                </Box>
                <AntSwitch
                  checked={values.footer_in_detailed_composition_bill}
                  onChange={(e) => {
                    setFieldValue(e.target.name, e.target.checked);
                  }}
                  name="footer_in_detailed_composition_bill"
                />
              </Box>
            </Grid> */}
            <Grid item xs={6}>
              <Box alignItems={"center"} display={"flex"}>
                <Box flex={1} component={"span"}>
                  Customer Copy
                </Box>
                <AntSwitch
                  checked={values.customer_copy}
                  onChange={(e) => {
                    setFieldValue(e.target.name, e.target.checked);
                  }}
                  name="customer_copy"
                />
              </Box>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>
      <Grid container style={{ padding: theme.spacing(3) }}>
        <Grid item xs={12}>
          <Box display={"flex"}>
            <Box flex={1}>
              <Button
                onClick={() => {
                  onBack();
                }}
              >
                <ArrowBackIosIcon /> Back
              </Button>
            </Box>
            <Box>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setModalStatus(!modalStatus);
                }}
              >
                Settings
              </Button>
              {/* <Button
                onClick={updateStatus}
                color="primary"
                variant="contained"
              >
                Update Status
              </Button> */}
            </Box>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Box style={{ backgroundColor: "white" }}>
            <Box style={{ margin: "10px 10px", marginLeft: "20px" }}>
              <meta
                httpEquiv="Content-Type"
                content="text/html; charset=utf-8"
              />
              <style
                dangerouslySetInnerHTML={{
                  __html:
                    "\n        .page-break {\n            page-break-after: always;\n        }\n        table{\n            border-collapse: collapse;\n            width: 100%;\n        }\n",
                }}
              />
              <div style={{ textAlign: "center" }}>
                <div
                  style={{
                    display: "flex",
                    width: "100%",
                    alignItems: "center",
                  }}
                >
                  <div style={{ fontWeight: "bold", fontSize: 22, flex: 1 }}>
                    TAX INVOICE
                  </div>
                  <div>(ORIGINAL FOR RECIPIENT)</div>
                </div>
              </div>

              <div id="copy_table">
                <table border={1}>
                  <tbody>
                    <tr>
                      <td colSpan={2}>
                        {_.upperCase(businessDetails?.name)}
                        <div>{businessDetails?.address}</div>
                        <div>
                          {businessDetails?.city_name +
                            ", " +
                            businessDetails?.state_name +
                            " (" +
                            businessDetails.pincode +
                            ")"}
                        </div>
                        <div> GSTIN: {businessDetails?.gst_number}</div>
                        <div>Mo: {businessDetails.contact_number}</div>
                        <div>Email: {businessDetails.email}</div>
                      </td>
                      <td rowSpan={2} colSpan={4}>
                        <table width="100%" height="200px">
                          <tbody>
                            <tr style={{ borderBottom: "1px solid" }}>
                              <td
                                style={{ borderRight: "1px solid" }}
                                width="50%"
                              >
                                Invoice Number
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {data.invoice_number}
                              </td>
                            </tr>
                            <tr style={{ borderBottom: "1px solid" }}>
                              <td
                                style={{ borderRight: "1px solid" }}
                                width="50%"
                              >
                                Date
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {moment(data.created_at).format("DD MMM YYYY")}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ borderRight: "1px solid" }}
                                width="50%"
                              >
                                Mode
                              </td>
                              <td style={{ textAlign: "left" }}>
                                {_.capitalize(data.payment_mode)}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <div>Buyer (Bill to)</div>
                        <Box fontWeight="600">{data?.name}</Box>
                        {/* <Box>{data.billing_address.line1}</Box>
                    <Box>{data.billing_address.city}</Box> */}
                        <Box>Mob: {data.mobile}</Box>
                        <Box>GSTIN: {data.business_id}</Box>
                        {/* <div>156, B-Block Sec-14 Udaipur (Raj.)</div>
                    <div>313001</div>
                    <div>Mo: 9460970462</div>
                    <div>Email: jainelectricals2022@gmail.com</div> */}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={5}>
                        <table>
                          <tbody>
                            <tr style={{ borderBottom: "1px solid" }}>
                              <td width="70px">S.No</td>
                              <td>Description of Goods</td>
                              <td>HSN/SAC</td>
                              <td>Quantity</td>
                              <td>Rate</td>
                              <td>{isDiscountProvided && "Discount(%)"}</td>
                              <td>GST</td>
                              <td>Rate (GST)</td>
                              <td>Amount (₹)</td>
                            </tr>
                            {data?.items.map((item, index) => (
                              <tr>
                                <td width="70px">{index + 1}</td>
                                <td>
                                  {item.name + " "}
                                  {Object.keys(item.options).length > 0 &&
                                    `[${Object.keys(item.options)
                                      .map((key) => {
                                        return `${key}: ${item.options[key].value}`;
                                      })
                                      .join(", ")}]`}
                                </td>
                                <td>{item.hsn}</td>
                                <td>{item.quantity}</td>
                                <td>
                                  {currency({
                                    value: item.product_price,
                                    currency: userData.currency,
                                  })}
                                </td>
                                <td>
                                  {isDiscountProvided && `${item.discount}%`}
                                </td>
                                <td>
                                  {item.tax}%
                                  {/* {currency({
                                value: item.total_price * (item.tax / 100),
                                currency: userData.currency,
                              })}{" "}
                              ({item.tax}%) */}
                                </td>
                                <td>
                                  {" "}
                                  {currency({
                                    value:
                                      item.product_price +
                                      (item.product_price * item.tax) / 100,
                                    currency: userData.currency,
                                  })}
                                </td>
                                <td>
                                  {currency({
                                    value: item.total_price,
                                    currency: userData.currency,
                                  })}
                                </td>
                              </tr>
                            ))}
                            <tr style={{ padding: 5 }}>
                              <td
                                colSpan={6}
                                style={{ textAlign: "right" }}
                              ></td>
                              <td> {data?.cart?.cart_total} </td>
                            </tr>
                            <tr>
                              <td
                                style={{ textAlign: "right", paddingRight: 30 }}
                                colSpan={8}
                              >
                                CGST
                              </td>
                              <td>
                                {currency({
                                  value: data?.total_taxable_amount / 2,
                                  currency: userData.currency,
                                })}
                              </td>
                            </tr>
                            <tr>
                              <td
                                style={{ textAlign: "right", paddingRight: 30 }}
                                colSpan={8}
                              >
                                SGST
                              </td>
                              <td>
                                {currency({
                                  value: data?.total_taxable_amount / 2,
                                  currency: userData.currency,
                                })}
                              </td>
                            </tr>
                            <tr style={{ borderTop: "1px solid" }}>
                              <td
                                style={{
                                  textAlign: "right",
                                  fontWeight: "bold",
                                  paddingRight: 30,
                                }}
                                colSpan={8}
                              >
                                Total
                              </td>
                              <td style={{ fontWeight: "bold" }}>
                                {currency({
                                  value: data?.total_payable_with_tax,
                                  currency: userData.currency,
                                })}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </td>
                    </tr>
                    <tr style={{ borderTop: "1px solid" }}>
                      <td style={{ paddingRight: 30 }}>
                        Amount Chargeable (in words)
                      </td>
                    </tr>
                    <tr style={{ borderTop: "1px solid" }}>
                      <td style={{ paddingRight: 30, fontWeight: "bold" }}>
                        INR{" "}
                        {data?.total_payable_with_tax &&
                          _.startCase(
                            converter.toWords(data?.total_payable_with_tax)
                          )}{" "}
                        Only
                      </td>
                    </tr>
                    <tr style={{ borderTop: "1px solid" }}>
                      <td
                        style={{
                          paddingRight: 30,
                          borderRight: "1px solid",
                          width: "50%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            height: 100,
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            Company PAN: {businessDetails?.pancard}
                          </div>
                          <div>
                            <div>Declaration:</div>
                            <div style={{ fontStyle: "italic" }}>
                              We declare that this invoice shows the actual
                              price of the goods described and that all
                              particulars are true and correct.
                            </div>
                          </div>
                        </div>
                      </td>
                      <td style={{ paddingRight: 30 }} colSpan={2}>
                        <div>Company's Bank Details</div>
                        <div>
                          A/C Holder Name:{" "}
                          {
                            businessDetails?.account_details
                              ?.account_holder_name
                          }
                        </div>
                        <div>
                          Bank Name:{" "}
                          {businessDetails?.account_details?.bank_name}
                        </div>
                        <div>
                          A/C No.:{" "}
                          {businessDetails?.account_details?.account_number}
                        </div>
                        <div>
                          IFS Code:{" "}
                          {businessDetails?.account_details?.ifsc_code}
                        </div>
                      </td>
                    </tr>
                    <tr style={{ borderTop: "1px solid", height: 100 }}>
                      <td
                        style={{
                          paddingRight: 30,
                          borderRight: "1px solid",
                          width: "50%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            height: 100,
                            flexDirection: "column",
                          }}
                        >
                          <div>Customer's Seal &amp; Signature</div>
                        </div>
                      </td>
                      <td
                        style={{ paddingRight: 30, textAlign: "right" }}
                        colSpan={2}
                      >
                        <div
                          style={{
                            display: "flex",
                            height: 100,
                            flexDirection: "column",
                          }}
                        >
                          <div style={{ flex: 1 }}>
                            For {businessDetails?.name}
                          </div>
                          <div>Authorised Signatory</div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>

              {values.customer_copy && (
                <>
                  <div style={{ textAlign: "center", marginTop: "100px" }}>
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                      }}
                    >
                      <div
                        style={{ fontWeight: "bold", fontSize: 22, flex: 1 }}
                      >
                        Tax Invoice
                      </div>
                      <div>(Customer Copy)</div>
                    </div>
                  </div>
                  <div id="customer_copy"></div>
                </>
              )}
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
}

export default Invoice;
