import {
  Box,
  Button,
  DialogContentText,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Radio,
  Select,
  TableContainer,
  TableHead,
  Typography,
  useTheme,
} from "@material-ui/core";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import CloseIcon from "@material-ui/icons/Close";
import clsx from "clsx";
import { useFormik } from "formik";
import moment from "moment";
import { useSnackbar } from "notistack";
import queryString from "query-string";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import useGeneralStyle from "../assets/css/general";
import { httpClient } from "../config/httpClient";
import useCallbackStatus from "../hooks/useCallbackStatus";
import Header from "../layouts/Header";
import ConfirmationModal from "../MuiComponent/ConfirmationModal";
import Datatable from "../MuiComponent/Datatable";
import Modal from "../MuiComponent/Modal";
import RemoveIcon from "@material-ui/icons/Remove";
import AddIcon from "@material-ui/icons/Add";
import DateFnsUtils from "@date-io/moment";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import TextField from "../MuiComponent/TextField";
import { useAuth } from "../providers/AuthProvider";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  addPOSOrder,
  createSlot,
  fetchCollectedOrders,
  fetchProducts,
  fetchSlots,
  generalApiCall,
  updateSlot,
} from "../store/actions";
import {
  selectCache,
  selectCollectedOrders,
  selectLoadingState,
  selectProducts,
  selectSlots,
} from "../store/selectors";
import { AntSwitch } from "./category/CategoryList";
import AutoCompleteInput from "../components/AutoCompleteInput";
import { addPOSOrderFormValidation } from "../validations/login-validate";
import ReactTable from "../MuiComponent/ReactTable";
import _ from "lodash";

// const tableHeaderPlacedOrder = [
//   { id: "date", align: "left", label: "DATE", minWidth: 170 },
//   { id: "slot", align: "left", label: "SLOT", minWidth: 170 },
//   { id: "id", align: "left", label: "ORDER NUMBER", minWidth: 170 },
//   { id: "name", align: "left", label: "NAME", minWidth: 170 },
//   { id: "items", align: "left", label: "ITEMS", minWidth: 170 },
//   { id: "mobile_number", align: "left", label: "MOBILE NUMBER", minWidth: 170 },
//   { id: "manage", align: "left", label: "Bill", minWidth: 100 },
//   { id: "manage", align: "left", label: "ACTION", minWidth: 100 },
// ];

// const tableHeader = [
//   { id: "date", align: "left", label: "DATE", minWidth: 170 },
//   { id: "slot", align: "left", label: "SLOT", minWidth: 170 },
//   { id: "id", align: "left", label: "ORDER NUMBER", minWidth: 170 },
//   { id: "name", align: "left", label: "NAME", minWidth: 170 },
//   { id: "items", align: "left", label: "ITEMS", minWidth: 170 },
//   { id: "mobile_number", align: "left", label: "MOBILE NUMBER", minWidth: 170 },
// ];

const useStyles = makeStyles((theme2) => ({
  root: {
    width: "100%",
    marginTop: "25px",
    overflowX: "auto",
    backgroundColor: "white",
    border: "1px solid #caced5",
  },
  container: {
    height: "440px",
    width: "100%",
  },
  inputLabel: {
    color: "color",
    fontSize: "13px",
    padding: "10px 0px",
  },
  activeFilter: {
    backgroundColor: theme2.palette.primary.main,
    color: "white",
    fontWeight: 600,
  },
}));

const renderHeader = (filter) => {
  if (filter == "placed") return tableHeaderPlacedOrder;
  else if (filter == "picked") return tableHeader;
  else return tableHeaderCancelled;
};
function CollectedOrders() {
  const classes = useStyles();
  const auth = useAuth();
  const history = useHistory();
  const tableRef = useRef();
  const location = useLocation();
  const slots = useSelector(selectSlots);
  const notification = useSnackbar();
  const tableLoader = useSelector(selectLoadingState("react_table_loader"));
  const productList = useSelector(selectProducts);
  const [dateFilter, setDateFilter] = useState(null);
  const addCategoryApiStatus = useCallbackStatus();
  const [addOrderModal, setAddOrderModal] = useState(false);
  const [confirmation, setConfirmation] = React.useState(false);
  const searchParams = useMemo(
    () => queryString.parse(location.search),
    [location.search]
  );
  const [searchProduct, setSearchProductKey] = useState("");
  const [keyPressCounter, setKeyPressCounter] = useState(0);
  const [filter, setFilter] = useState({
    status: searchParams.status,
    date: null,
    search_string: null,
  });

  const [selectedOrder, setSelectedOrder] = React.useState({});
  const { data: orderList, meta } = useSelector(selectCollectedOrders);
  const [modal, setModal] = React.useState(false);
  const globalClasses = useGeneralStyle();
  const loading = useSelector(selectLoadingState("actionLoader"));

  const autoCompleteRef = useRef();
  const dispatch = useDispatch();

  const theme = useTheme();

  const fileHandler = (file, alt) => {
    if (file && file !== "") {
      console.log("coming in if condition.", file);
      setValues({ ...values, image_name: file.name, image: file });
    }
  };

  const columns = useMemo(() => {
    let column = [
      {
        header: "DATE",
        key: "created_at",
        type: "date",
        dateFormat: "DD MMM YYYY, hh:mm a",
      },
      { header: "SLOT", key: "slot", type: "date", dateFormat: "DD MMM YYYY" },
      { header: "ORDER NUMBER", key: "order_id" },
      { header: "NAME", key: "name" },
      { header: "ITEMS", key: "items" },
      { header: "MOBILE NUMBER", key: "mobile_number" },
    ];

    if (["picked"].includes(filter.status)) {
      column.push({
        header: "VIEW BILL",
        type: "custom_cell",
        cell: (info) => (
          <a
            className={globalClasses.link}
            style={{ color: "#2895c4" }}
            onClick={() => {
              history.push(`/point-of-sale/${info.row.original.pos_id}`);
            }}
          >
            View Bill
          </a>
        ),
      });
    }

    if (["placed"].includes(filter.status)) {
      column.push({
        header: "BILL",
        type: "custom_cell",
        cell: (info) => (
          <button
            onClick={() => {
              if (filter.status == "placed")
                history.push("/point-of-sale/add", info.row.original);
              else history.push(`/point-of-sale/${info.row.original.pos_id}`);
            }}
          >
            Create Bill
          </button>
        ),
      });

      column.push({
        header: "ACTION",
        type: "custom_cell",
        cell: (info) => (
          <button
            onClick={() => {
              setSelectedOrder(info.row.original);
              setConfirmation(!confirmation);
            }}
          >
            Cancel
          </button>
        ),
      });
    }

    return column;
  }, [filter]);

  let {
    values,
    touched,
    errors,
    setFieldValue,
    setValues,
    resetForm,
    handleBlur,
    submitForm,
    handleChange,
  } = useFormik({
    initialValues: {
      slot_date: moment(),
    },
    onSubmit: () => {
      dispatch(
        createSlot({ ...values, business_id: auth.userData.business_id })
      );
    },
  });

  let addOrderForm = useFormik({
    initialValues: {
      name: "",
      slot: "",
      entity_type: "partner",
      business_id: auth.userData.business_id,
      phone_prefix: "91",
      contact_number: "",
      products: [],
    },
    validationSchema: addPOSOrderFormValidation,
    onSubmit: () => {
      let apiBody = {
        ...addOrderForm.values,
        products: addOrderForm.values.products.map((item) => {
          let { product_id, quantity } = item;
          return {
            product_id,
            quantity,
          };
        }),
      };

      dispatch(
        addPOSOrder({
          ...apiBody,
          callback: () => {
            setAddOrderModal(!addOrderModal);
            tableRef.current.refreshDatatable();
            notification.enqueueSnackbar("Order Placed Successfully", {
              variant: "success",
            });
          },
        })
      );
    },
  });

  useEffect(() => {
    dispatch(
      fetchSlots({
        business_id: auth.userData.business_id,
      })
    );

    dispatch(
      fetchProducts({
        business_id: auth.userData.business_id,
        pagination: false,
      })
    );
  }, []);

  const globalSearchHandler = useCallback((e) => {
    let params = queryString.parse(location.search);
    params.search_string = e.target.value;

    filter.search_string = e.target.value;
    setFilter({ ...filter });
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      tableRef.current.refreshDatatable();
    }, 300);

    Object.keys(filter).forEach((key) => {
      if (filter[key] == null || filter[key] == "") {
        delete filter[key];
      }
    });

    history.replace(location.pathname + "?" + `${new URLSearchParams(filter)}`);

    return () => clearTimeout(delayDebounceFn);
  }, [filter.status, filter.search_string, filter.date]);

  const ModalHeader = () => {
    return (
      <>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>Add Slots</Box>
          <Box>
            <IconButton onClick={() => setModal(!modal)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </>
    );
  };

  const getTableData = useCallback(
    (apiBody) => {
      console.log(
        "REACT TABLE PAGE INDEX MANUAL PAGINATION: GET API INVOKED: ",
        apiBody
      );

      dispatch(fetchCollectedOrders(apiBody));
    },
    [dispatch]
  );

  const onKeyDownHandler = useCallback((e) => {
    if (e.key == "Enter") {
      keyPressCounter += 1;
      setKeyPressCounter(keyPressCounter);
      if (keyPressCounter > 1) handleKeyDown(e, "discount");
      else setKeyPressCounter(0);
    }
  }, []);

  const onChangeHandler = useCallback((e) => {
    setSearchProductKey(e.target.value);
  }, []);

  const onSelectionHandler = useCallback(
    (selection) => {
      if (typeof selection === "object") {
        setSearchProductKey("");

        let { name, id: product_id } = selection;
        addOrderForm.values.products.push({ name, product_id, quantity: 1 });
        addOrderForm.setValues({ ...addOrderForm.values });
      }
    },
    [addOrderForm.values]
  );

  const AddOrderModalHeader = () => {
    return (
      <>
        <Box display="flex" alignItems="center">
          <Box flexGrow={1}>Add Order</Box>
          <Box>
            <IconButton onClick={() => setAddOrderModal(!addOrderModal)}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Box>
      </>
    );
  };

  return (
    <>
      <ConfirmationModal
        height="170px"
        width="auto"
        title="Are you sure"
        status={confirmation}
        closeBtnText="cancel"
        saveBtnText="Submit"
        closeHandler={() => setConfirmation(!confirmation)}
        saveHandler={() => {
          dispatch(
            generalApiCall({
              endpoint: `/partner/updatePOSOrder/${selectedOrder.id}`,
              method: "PUT",
              apiBody: {
                status: "cancelled",
              },
              key: "general_request",
              callback: () => {
                setConfirmation(!confirmation);
                tableRef.current.refreshDatatable();
                notification.enqueueSnackbar("Order cancelled successfully", {
                  variant: "success",
                });
              },
            })
          );
        }}
      >
        <Box height={"100%"} alignContent={"center"}>
          Are you sure want to cancel order number #{selectedOrder.order_id}
        </Box>
      </ConfirmationModal>

      <Modal
        height="450px"
        width="100%"
        loading={addCategoryApiStatus.isPending}
        headerComponent={ModalHeader}
        status={modal}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Box display="flex" flexDirection="column">
                <InputLabel className={globalClasses.inputLabel}>
                  Slot
                </InputLabel>
                <Box style={{ gap: "20px" }} display={"flex"}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDatePicker
                      inputVariant="outlined"
                      fullWidth
                      disablePast
                      className={classes.inputTextField}
                      id="date-picker-dialog"
                      format="YYYY-MMM-DD"
                      TextFieldComponent={(props) => {
                        return (
                          <TextField
                            {...props}
                            value={moment(values.slot_date).format(
                              "YYYY-MM-DD"
                            )}
                            InputProps={{
                              ...props.InputProps,
                              className: classes.inputTextField,
                            }}
                          />
                        );
                      }}
                      onChange={(date) => {
                        console.log(
                          "DOB DATE SELECTED: ",
                          moment(date).format("YYYY-MM-DD hh:mm:ss a"),
                          moment(date).format("YYYY-MM-DD HH:mm:ss")
                        );

                        setFieldValue(
                          "slot_date",
                          moment(date).format("YYYY-MM-DD HH:mm:ss")
                        );
                      }}
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                        id: `datePicker1`,
                        // style: { color: "black" },
                      }}
                    />
                  </MuiPickersUtilsProvider>
                  <Button
                    onClick={submitForm}
                    style={{ height: "45px" }}
                    variant="contained"
                    color="primary"
                  >
                    Add
                  </Button>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={globalClasses.inputLabel}>
                Current Slots
              </InputLabel>
              <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                  <TableHead>
                    <TableRow>
                      <TableCell>Default</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell>Status</TableCell>
                      <TableCell>Manage</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {slots.map((slot) => {
                      return (
                        <TableRow
                          key={slot.id}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            <Radio
                              value={slot.id}
                              checked={slot.default}
                              onChange={(e) => {
                                dispatch(
                                  updateSlot({
                                    id: slot.id,
                                    default: slot.id,
                                  })
                                );
                              }}
                            />
                          </TableCell>
                          <TableCell>
                            {moment(slot.slot_date).format("D-M-Y")}
                          </TableCell>
                          <TableCell>
                            <AntSwitch
                              checked={slot.status == "active" ? true : false}
                              onChange={(e) => {
                                dispatch(
                                  updateSlot({
                                    id: slot.id,
                                    status: e.target.checked
                                      ? "active"
                                      : "inactive",
                                  })
                                );
                              }}
                              name="status"
                            />
                          </TableCell>
                          <TableCell>
                            <IconButton
                              onClick={() =>
                                dispatch(
                                  updateSlot({
                                    id: slot.id,
                                    delete: true,
                                  })
                                )
                              }
                              style={{ paddingLeft: "10px" }}
                            >
                              <DeleteIcon style={{ color: "red" }} />
                            </IconButton>
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>

      <Modal
        height="80vh"
        width="100%"
        loading={loading}
        headerComponent={AddOrderModalHeader}
        status={addOrderModal}
        closeBtnText="cancel"
        saveBtnText="Submit"
        closeHandler={() => setAddOrderModal(!addOrderModal)}
        saveHandler={addOrderForm.submitForm}
      >
        <DialogContentText id="alert-dialog-description">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputLabel className={globalClasses.inputLabel}>Name</InputLabel>
              <TextField
                id="name"
                name="name"
                error={
                  addOrderForm.touched.name && addOrderForm.errors.name
                    ? true
                    : false
                }
                helperText={
                  addOrderForm.touched.name && addOrderForm.errors.name
                }
                onChange={addOrderForm.handleChange}
                onBlur={addOrderForm.handleBlur}
                value={addOrderForm.values.name}
                variant="outlined"
                placeholder="Customer Name"
              />
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={globalClasses.inputLabel}>
                Mobile Number
              </InputLabel>
              <TextField
                id="contact_number"
                name="contact_number"
                error={
                  addOrderForm.touched.contact_number &&
                  addOrderForm.errors.contact_number
                    ? true
                    : false
                }
                helperText={
                  addOrderForm.touched.contact_number &&
                  addOrderForm.errors.contact_number
                }
                onChange={addOrderForm.handleChange}
                onBlur={addOrderForm.handleBlur}
                value={addOrderForm.values.contact_number}
                variant="outlined"
                placeholder="Mobile Number"
              />
            </Grid>
            <Grid item xs={12}>
              {addOrderForm.values.products.map((item, index) => {
                return (
                  <Box
                    style={{
                      border: "1px solid gray",
                      padding: "10px",
                      margin: "20px 0px",
                    }}
                    key={index}
                    display={"flex"}
                    alignItems={"center"}
                  >
                    <Box flex={1}>{item.name}</Box>
                    <Box display={"flex"} alignItems={"center"}>
                      <IconButton
                        onClick={() => {
                          if (
                            addOrderForm.values.products[index].quantity > 1
                          ) {
                            addOrderForm.values.products[index].quantity =
                              item.quantity - 1;
                            addOrderForm.setValues({ ...addOrderForm.values });
                          } else {
                            addOrderForm.values.products.splice(index, 1);
                            setValues({ ...addOrderForm.values });
                          }
                        }}
                        style={{
                          backgroundColor: "#ed1e24",
                          color: "white",
                          borderRadius: "1px",
                        }}
                      >
                        <RemoveIcon style={{ fontSize: "16px" }} />
                      </IconButton>
                      <Box paddingX={2}>{item.quantity}</Box>
                      <IconButton
                        onClick={() => {
                          addOrderForm.values.products[index].quantity =
                            item.quantity + 1;
                          addOrderForm.setValues({ ...addOrderForm.values });
                        }}
                        style={{
                          backgroundColor: "#ed1e24",
                          color: "white",
                          borderRadius: "1px",
                        }}
                      >
                        <AddIcon style={{ fontSize: "16px" }} />
                      </IconButton>
                    </Box>
                  </Box>
                );
              })}

              <InputLabel className={globalClasses.inputLabel}>
                Select Products
              </InputLabel>
              <AutoCompleteInput
                value={searchProduct}
                onKeyDown={onKeyDownHandler}
                ref={autoCompleteRef}
                options={productList}
                onChange={onChangeHandler}
                onSelection={onSelectionHandler}
              />
              <FormHelperText error variant="outlined">
                {addOrderForm.touched.products && addOrderForm.errors.products}
              </FormHelperText>
            </Grid>
            <Grid item xs={12}>
              <InputLabel className={globalClasses.inputLabel}>
                Select Slot
              </InputLabel>
              <Select
                error={
                  addOrderForm.touched.slot && addOrderForm.errors.slot
                    ? true
                    : false
                }
                helperText={
                  addOrderForm.touched.slot && addOrderForm.errors.slot
                }
                name="slot"
                onChange={(e) => {
                  addOrderForm.setFieldValue("slot", e.target.value);
                }}
                fullWidth
                variant="outlined"
              >
                {slots.map((slot, index) => {
                  return (
                    <MenuItem key={index} value={slot.id}>
                      {moment(slot.slot_date).format("DD/MM/YYYY")}
                    </MenuItem>
                  );
                })}
              </Select>
              <FormHelperText error variant="outlined">
                {addOrderForm.touched.slot && addOrderForm.errors.slot}
              </FormHelperText>
            </Grid>
          </Grid>
        </DialogContentText>
      </Modal>

      <Header>
        <Typography style={{ flexGrow: 1 }} variant="h6" noWrap>
          Collected Orders
        </Typography>
      </Header>

      <Box display="flex" style={{ backgroundColor: "white" }}>
        <Box style={{ margin: "10px 10px", marginLeft: "20px" }}>
          <Button
            disableElevation
            onClick={() => {
              filter.status = "placed";
              setFilter({ ...filter });
            }}
            className={clsx({
              [classes.activeFilter]: filter.status === "placed",
            })}
            variant="contained"
          >
            PLACED
          </Button>
        </Box>
        <Box style={{ margin: "10px 10px" }}>
          <Button
            disableElevation
            variant="contained"
            className={clsx({
              [classes.activeFilter]: filter.status === "picked",
            })}
            onClick={() => {
              filter.status = "picked";
              setFilter({ ...filter });
            }}
          >
            Picked
          </Button>
        </Box>
        <Box style={{ margin: "10px 10px" }}>
          <Button
            disableElevation
            variant="contained"
            className={clsx({
              [classes.activeFilter]: filter.status === "cancelled",
            })}
            onClick={() => {
              filter.status = "cancelled";
              setFilter({ ...filter });
            }}
          >
            CANCELLED
          </Button>
        </Box>
      </Box>
      <Grid container style={{ padding: theme.spacing(3) }}>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px 0px",
          }}
          item
          xs={12}
          sm={4}
        >
          <Box style={{ paddingRight: "20px" }}>Search</Box>
          <TextField
            value={filter.search_string}
            onChange={globalSearchHandler}
            variant="outlined"
            style={{ width: "100%" }}
            inputProps={{
              style: { padding: "12px", backgroundColor: "white" },
            }}
            placeholder="Name, Mobile Number or Order Id"
          />
        </Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            padding: "10px 30px",
          }}
          item
          xs={12}
          sm={3}
        >
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              inputVariant="outlined"
              placeholder="-select date-"
              fullWidth
              className={classes.inputTextField}
              id="date-picker-dialog"
              value={filter.date ? moment(filter.date) : null}
              format="YYYY-MMM-DD"
              TextFieldComponent={(props) => {
                return (
                  <TextField
                    {...props}
                    value={
                      filter.date
                        ? moment(filter.date).format("YYYY-MM-DD")
                        : "Select Slot Date"
                    }
                    InputProps={{
                      ...props.InputProps,
                      className: classes.inputTextField,
                    }}
                  />
                );
              }}
              labelFunc={() =>
                filter.date
                  ? moment.unix(filter.date).format("D MMM Y")
                  : "- Select date -"
              }
              onChange={(date) => {
                filter.date = moment(date).format("YYYY-MM-DD");
                setFilter({ ...filter });
              }}
              KeyboardButtonProps={{
                "aria-label": "change date",
                id: `datePicker1`,
              }}
            />
          </MuiPickersUtilsProvider>
        </Grid>
        <Grid
          style={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row-reverse",
            padding: "10px 0px",
          }}
          item
          xs={5}
        >
          <Box marginLeft={2}>
            <Button
              onClick={() => {
                addOrderForm.resetForm();
                addOrderForm.setFieldValue("products", []);
                setAddOrderModal(!modal);
              }}
              variant="contained"
              color="primary"
            >
              Add Order
            </Button>
          </Box>
          <Box>
            <Button
              onClick={() => {
                setModal(true);
              }}
              variant="contained"
              color="primary"
            >
              Slots
            </Button>
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Paper className={classes.root}>
            <ReactTable
              ref={tableRef}
              fetchData={getTableData}
              isLoading={tableLoader}
              totalRecords={meta?.total}
              columns={columns}
              data={orderList || []}
              manualPagination={true}
            />
            {/* <Datatable
              getDataList={getOrderList}
              totalRecords={totalPages}
              loading={getBannersApiStatus.isPending}
              tableHeader={
                renderHeader(filter)
              }
            >
              {orderList.map((orderData, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      {moment(orderData.created_at).format(
                        "DD MMM YYYY, hh:mm a"
                      )}
                    </TableCell>
                    <TableCell>
                      {moment(orderData.slot).format("DD/MM/YYYY")}
                    </TableCell>
                    <TableCell>{orderData.order_id}</TableCell>
                    <TableCell>{orderData.name}</TableCell>
                    <TableCell>{orderData.items}</TableCell>
                    <TableCell>{orderData.mobile_number}</TableCell>
                    {["picked"].includes(filter) && (
                      <TableCell>
                        <a
                          className={globalClasses.link}
                          style={{ color: "#2895c4" }}
                          onClick={() => {
                            history.push(`/point-of-sale/${orderData.pos_id}`);
                          }}
                        >
                          View Bill
                        </a>
                      </TableCell>
                    )}
                    {["placed"].includes(filter) && (
                      <>
                        <TableCell>
                          <a
                            className={globalClasses.link}
                            style={{ color: "#2895c4" }}
                            onClick={() => {
                              if (filter == "placed")
                                history.push("/point-of-sale/add", orderData);
                              else
                                history.push(
                                  `/point-of-sale/${orderData.pos_id}`
                                );
                            }}
                          >
                            Create Bill
                          </a>
                        </TableCell>
                        <TableCell>
                          <a
                            className={globalClasses.link}
                            style={{ color: "#2895c4" }}
                            onClick={() => {
                              setSelectedOrder(orderData);
                              setConfirmation(!confirmation);
                            }}
                          >
                            Cancel
                          </a>
                        </TableCell>
                      </>
                    )}
                  </TableRow>
                );
              })}
            </Datatable> */}
          </Paper>
        </Grid>
      </Grid>
      {/* <DetailView
            data={orderDetail}
            onBack={() => {
              setView("false");
              getOrderList();
            }}
          /> */}
    </>
  );
}

export default CollectedOrders;
